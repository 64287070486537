import { setterReducer } from "~/shared/setter";

import getInitialState from "~/meetScoreboard/reducers/initial";

// eslint-disable-next-line default-param-last
export default (state = getInitialState(), action) => {
    let nextState = state;
    nextState = setterReducer(nextState, action, getInitialState);
    return nextState;
};
