import React from "react";
import PropTypes from "prop-types";

const UnknownErrorMessage = ({ clean = false }) => (
    <div className={`c-blankslate ${clean ? "c-blankslate--clean" : ""}`}>
        <h3 className="c-blankslate__title">Something went wrong</h3>
        <p>We are working on fixing the problem</p>
    </div>
);

UnknownErrorMessage.propTypes = {
    clean: PropTypes.bool,
};

export default UnknownErrorMessage;
