import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import styles from "~/shared/components/MeetCore/Scoreboard/Scenes/Scenes.less";

function ScoreboardSkeleton() {
    const numLanes = useSelector((state) => state.numLanes);

    return (
        <>
            <div className={styles.scoreboard}>
                <div className={styles.header}>
                    <div>
                        <div className="c-skeleton is-title u-mb--" />
                        <div className="o-spacer">
                            <span className="c-skeleton is-meta" />
                            <span className="c-skeleton is-meta" />
                        </div>
                    </div>
                </div>
                <table className={`table ${styles.table}`}>
                    <tbody>
                        {_.range(numLanes || 10).map((lane) => (
                            <tr key={`scoreboard-skeleton-${lane}`}>
                                <td>
                                    <div
                                        className="c-skeleton is-title"
                                        style={{
                                            height: "0.9em",
                                            width: "120px",
                                        }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="o-spacer u-p+">
                <div
                    className="c-skeleton is-meta u-ml-auto"
                    style={{ width: "60px" }}
                />
            </div>
        </>
    );
}

export default ScoreboardSkeleton;
