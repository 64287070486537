import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import BatchDisplay from "~/shared/components/MeetCore/Scoreboard/Scenes/BatchDisplay";
import styles from "~/shared/components/MeetCore/Scoreboard/Scenes/Scenes.less";

function DualScoreTable({ dualScores = [] }) {
    const renderDualScoreRow = (dualScore) => {
        const { id, opponent, opponent_score, team, team_result, team_score } =
            dualScore || {};
        const { abbr: teamAbbr } = team || {};
        const { abbr: opponentAbbr } = opponent || {};

        const tie = Number(team_score) === Number(opponent_score);
        const homeTeamWon = !tie && team_result;
        const awayTeamWon = !tie && !team_result;

        return (
            <tr key={id}>
                <td
                    className={`u-text-end u-text-normal ${styles.cellPrimary}`}
                >
                    {teamAbbr}
                </td>
                <td
                    className={classNames({
                        [styles.cellScore]: true,
                        [styles.cellScoreWon]: homeTeamWon,
                    })}
                >
                    {Number(team_score)}
                </td>
                <td
                    className={classNames({
                        [styles.cellScore]: true,
                        [styles.cellScoreWon]: awayTeamWon,
                    })}
                >
                    {Number(opponent_score)}
                </td>
                <td className={`u-text-normal ${styles.cellPrimary}`}>
                    {opponentAbbr}
                </td>
            </tr>
        );
    };

    return dualScores ? (
        <BatchDisplay items={dualScores} renderItem={renderDualScoreRow} />
    ) : null;
}

DualScoreTable.propTypes = {
    dualScores: PropTypes.arrayOf(PropTypes.shape({})),
};

export default DualScoreTable;
