import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import ErrorBoundary from "~/shared/components/ErrorBoundary";
import { initAxios } from "~/shared/utils";

import App from "~/meetScoreboard/components/App";
import Fallback from "~/meetScoreboard/components/Fallback";
import store from "~/meetScoreboard/store";

initAxios();

const container = document.getElementById("meet-scoreboard");
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <ErrorBoundary fallback={<Fallback />}>
            <App />
        </ErrorBoundary>
    </Provider>,
);
