import React from "react";

import UnknownErrorMessage from "~/shared/components/UnknownErrorMessage";

function Fallback() {
    return (
        <div className="c-card">
            <UnknownErrorMessage clean />
        </div>
    );
}

export default Fallback;
