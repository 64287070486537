import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import classNames from "classnames";

function DualScores({ doubleDual = false }) {
    const meetId = useSelector((state) => state.meetId);
    const topTeams = useSelector((state) => state.topTeams);
    const dualScores = useSelector((state) => state.dualScores);

    function renderDualContent() {
        if (!topTeams || topTeams.length < 2 || doubleDual) return null;

        const teamOne = topTeams[0];
        const teamTwo = topTeams[1];

        const {
            id: teamOneId,
            small_logo_url: teamOneSmallLogoUrl,
            short_name: teamOneShortName,
            meet_score: teamOneMeetScore,
        } = teamOne || {};
        const {
            id: teamTwoId,
            small_logo_url: teamTwoSmallLogoUrl,
            short_name: teamTwoShortName,
            meet_score: teamTwoMeetScore,
        } = teamTwo || {};

        const parsedTeamOneMeetScore = teamOneMeetScore
            ? parseInt(teamOneMeetScore, 10)
            : 0;
        const parsedTeamTwoMeetScore = teamTwoMeetScore
            ? parseInt(teamTwoMeetScore, 10)
            : 0;

        return (
            <div className="c-card c-card--large">
                <div className="c-card__item">
                    <div className="u-flex u-flex-align-items-center u-text-center">
                        <div className="u-flex-grow1 u-overflow-hidden">
                            <a
                                className="u-text-semi u-text-large u-text-default@xs"
                                href={`/results/${meetId}/team/${teamOneId}/${window.location.search}`}
                            >
                                <img
                                    className="c-avatar c-avatar--tiny u-object-contain u-center u-mb"
                                    src={teamOneSmallLogoUrl}
                                    alt={`${teamOneShortName} logo`}
                                    width={50}
                                    height={50}
                                />
                                <div className="u-text-truncate">
                                    {teamOneShortName}
                                </div>
                            </a>
                        </div>
                        <div className="u-nowrap u-text-display u-text-headline@xs u-text-bold u-mh++">
                            {parsedTeamOneMeetScore || 0} -{" "}
                            {parsedTeamTwoMeetScore || 0}
                        </div>
                        <div className="u-flex-grow1 u-overflow-hideen">
                            <a
                                className="u-text-semi u-text-large u-text-default@xs"
                                href={`/results/${meetId}/team/${teamTwoId}/${window.location.search}`}
                            >
                                <img
                                    className="c-avatar c-avatar--tiny u-object-contain u-center u-mb"
                                    src={teamTwoSmallLogoUrl}
                                    alt={`${teamTwoShortName} logo`}
                                    width={50}
                                    height={50}
                                />
                                <div className="u-text-truncate">
                                    {teamTwoShortName}
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    function renderDoubleDualContent() {
        if (!dualScores || !dualScores.length || !doubleDual) return null;

        return (
            <div className="c-card c-card--large u-overflow-hidden">
                <div className="c-card__item">
                    <h2 className="c-title">Dual scores</h2>
                </div>
                <div className="c-table-clean--responsive">
                    <table className="c-table-clean c-table-clean--middle table table-hover">
                        <thead>
                            <tr>
                                <th className="u-text-end">Team 1</th>
                                <th className="u-text-center">vs</th>
                                <th>Team 2</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dualScores.map((dualScore) => {
                                const {
                                    id,
                                    team,
                                    team_won,
                                    team_result,
                                    team_score,
                                    opponent,
                                    opponent_won,
                                    opponent_result,
                                    opponent_score,
                                } = dualScore || {};
                                const {
                                    id: teamId,
                                    small_logo_url: teamSmallLogoUrl,
                                    short_name: teamShortName,
                                } = team || {};
                                const {
                                    id: opponentId,
                                    small_logo_url: opponentSmallLogoUrl,
                                    short_name: opponentShortName,
                                } = opponent || {};

                                const parsedTeamScore = team_score
                                    ? parseInt(team_score, 10)
                                    : 0;
                                const parsedOpponentScore = opponent_score
                                    ? parseInt(opponent_score, 10)
                                    : 0;

                                return (
                                    <tr key={id}>
                                        <td className="u-text-end">
                                            <a
                                                className="u-text-semi u-nowrap"
                                                href={`/results/${meetId}/team/${teamId}/${window.location.search}`}
                                            >
                                                {teamShortName}
                                                <img
                                                    className="c-table-clean__img u-nowrap u-mr--"
                                                    src={teamSmallLogoUrl}
                                                    alt={`${teamShortName} logo`}
                                                />
                                            </a>
                                        </td>
                                        <td className="u-text-center">
                                            <span
                                                className={classNames({
                                                    "text-success":
                                                        team_won === true,
                                                    "text-danger":
                                                        team_won === false,
                                                    "text-warning":
                                                        team_won === null,
                                                })}
                                            >
                                                ({team_result}){" "}
                                                {parsedTeamScore || 0}
                                            </span>
                                            &ndash;
                                            <span
                                                className={classNames({
                                                    "text-success":
                                                        opponent_won === true,
                                                    "text-danger":
                                                        opponent_won === false,
                                                    "text-warning":
                                                        opponent_won === null,
                                                })}
                                            >
                                                {parsedOpponentScore || 0} (
                                                {opponent_result})
                                            </span>
                                        </td>
                                        <td>
                                            <a
                                                className="u-text-semi u-nowrap"
                                                href={`/results/${meetId}/team/${opponentId}/${window.location.search}`}
                                            >
                                                <img
                                                    className="c-table-clean__img u-nowrap u-mr--"
                                                    src={opponentSmallLogoUrl}
                                                    alt={`${opponentShortName} logo`}
                                                />
                                                {opponentShortName}
                                            </a>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    return (
        <>
            {renderDualContent()}
            {renderDoubleDualContent()}
        </>
    );
}

DualScores.propTypes = {
    doubleDual: PropTypes.bool,
};

export default DualScores;
