import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import HeaderLiveLabel from "~/shared/components/MeetCore/Scoreboard/Scenes/HeaderLiveLabel";
import HeaderExpandButton from "~/shared/components/MeetCore/Scoreboard/Scenes/HeaderExpandButton";

function LiveHeatSheetHeader({
    eventName,
    eventNumber,
    heatNumber,
    timer,
    isDiving,
    isExpanded,
    eventHeatsCount = null,
}) {
    const showNumberOfHeats = heatNumber <= eventHeatsCount;

    return (
        <>
            <div className="u-flex-grow1">
                <h4 className="c-scoreboard-sheet__title">{eventName}</h4>
                <ul
                    className={classNames({
                        "o-list-inline o-list-inline--dotted": true,
                        "u-text-small": !isExpanded,
                    })}
                >
                    <HeaderLiveLabel />
                    {!isDiving && (
                        <>
                            <li>Event {eventNumber}</li>
                            <li>
                                Heat {heatNumber}
                                {showNumberOfHeats
                                    ? ` of ${eventHeatsCount}`
                                    : null}
                            </li>
                        </>
                    )}
                </ul>
            </div>
            <div
                className={classNames({
                    "c-body2 u-text-large": true,
                    "u-text-huge": isExpanded,
                })}
            >
                {timer}
            </div>
            <HeaderExpandButton isExpanded={isExpanded} />
        </>
    );
}

LiveHeatSheetHeader.propTypes = {
    eventName: PropTypes.string.isRequired,
    eventNumber: PropTypes.number.isRequired,
    heatNumber: PropTypes.number.isRequired,
    eventHeatsCount: PropTypes.number,
    timer: PropTypes.string.isRequired,
    isDiving: PropTypes.bool.isRequired,
    isExpanded: PropTypes.bool.isRequired,
};

export default LiveHeatSheetHeader;
