import React from "react";
import PropTypes from "prop-types";

import { captureError } from "../utils";

import UnknownErrorMessage from "./UnknownErrorMessage";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error) {
        captureError(error);
    }

    render() {
        const { fallback, children } = this.props;
        const { hasError } = this.state;
        return hasError ? fallback || <UnknownErrorMessage /> : children;
    }
}

ErrorBoundary.defaultProps = {
    fallback: null,
    children: null,
};

ErrorBoundary.propTypes = {
    fallback: PropTypes.node,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

export default ErrorBoundary;
