import { SPLIT_DISTANCES } from "~/shared/constants";
import { decimalToSwim } from "~/shared/utils";

export const getSplashBackups = ({ canManageMeet, splash }) => {
    if (!splash) return [];

    let backupOneValue = null;
    let backupTwoValue = null;
    let backupThreeValue = null;

    if (canManageMeet) {
        const splashExtraData = splash.extra_data || {};

        backupOneValue = splashExtraData.backup_1 || null;
        backupTwoValue = splashExtraData.backup_2 || null;
        backupThreeValue = splashExtraData.backup_3 || null;
    } else {
        backupOneValue = splash.backup_1;
        backupTwoValue = splash.backup_2;
        backupThreeValue = splash.backup_3;
    }

    return [
        backupOneValue ? Number(backupOneValue) : null,
        backupTwoValue ? Number(backupTwoValue) : null,
        backupThreeValue ? Number(backupThreeValue) : null,
    ];
};

export const getSplashBackupCalculated = ({ canManageMeet, splash }) => {
    if (!splash) return null;

    let backupCalculated = null;

    if (canManageMeet) {
        const splashExtraData = splash.extra_data || {};

        backupCalculated = splashExtraData.backup_calculated || null;
    } else {
        backupCalculated = splash.backup_calculated;
    }

    return backupCalculated;
};

export const getBackupOptions = ({ backups, adjustedBackup }) => {
    const backupOptions = backups.map((backup, index) => {
        const backupNumber = index + 1;

        return {
            id: `backup_${backupNumber}`,
            name: `Backup ${backupNumber}`,
            value: decimalToSwim(backup),
        };
    });
    const adjustedBackupOption = {
        id: "backup_calculated",
        name: "Adjusted time",
        value: decimalToSwim(adjustedBackup),
    };

    return [...backupOptions, adjustedBackupOption];
};

export const getSplashStopwatches = ({ canManageMeet, splash }) => {
    if (!splash) return [];

    let stopwatchOneValue = null;
    let stopwatchTwoValue = null;
    let stopwatchThreeValue = null;

    if (canManageMeet) {
        const splashExtraData = splash.extra_data || {};

        stopwatchOneValue = splashExtraData.stopwatch_1 || null;
        stopwatchTwoValue = splashExtraData.stopwatch_2 || null;
        stopwatchThreeValue = splashExtraData.stopwatch_3 || null;
    } else {
        stopwatchOneValue = splash.stopwatch_1;
        stopwatchTwoValue = splash.stopwatch_2;
        stopwatchThreeValue = splash.stopwatch_3;
    }

    return [stopwatchOneValue, stopwatchTwoValue, stopwatchThreeValue];
};

export const getSplashStopwatchCalculated = ({ canManageMeet, splash }) => {
    if (!splash) return null;

    let stopwatchCalculated = null;

    if (canManageMeet) {
        const splashExtraData = splash.extra_data || {};

        stopwatchCalculated = splashExtraData.stopwatch_calculated || null;
    } else {
        stopwatchCalculated = splash.stopwatch_calculated;
    }

    return stopwatchCalculated;
};

export const getStopwatchOptions = ({ stopwatches }) => {
    const stopwatchOptions = stopwatches.map((stopwatch, index) => {
        const stopwatchNumber = index + 1;

        return {
            id: `stopwatch_${stopwatchNumber}`,
            name: `Stopwatch ${stopwatchNumber}`,
            value: stopwatch,
        };
    });

    return stopwatchOptions;
};

export const getSplashSplitDistance = ({ canManageMeet, splash }) => {
    if (!splash) return null;

    let splitDistance = null;

    if (canManageMeet) {
        const splashSplit = splash.split || {};

        splitDistance = splashSplit.splitdistance
            ? String(splash.split.splitdistance)
            : null;
    } else {
        splitDistance = splash.splitdistance
            ? String(splash.splitdistance)
            : null;
    }

    return splitDistance;
};

export const getSplashSplitDistances = ({ canManageMeet, splash }) => {
    if (!splash) return [];

    const splitDistances = SPLIT_DISTANCES.filter(
        (distance) => distance < splash.eventdistance,
    );

    const splashSplit = splash.split || {};

    if (
        canManageMeet &&
        splashSplit.splitdistance &&
        !splitDistances.includes(splashSplit.splitdistance)
    ) {
        splitDistances.push(splashSplit.splitdistance);
        return splitDistances;
    }

    if (
        !canManageMeet &&
        splash.splitdistance &&
        !splitDistances.includes(splash.splitdistance)
    ) {
        splitDistances.push(splash.splitdistance);
        return splitDistances;
    }

    return splitDistances;
};

export const getSplashSplitTimes = ({ canManageMeet, splash }) => {
    if (!splash) return [];

    let splitTimes = [];

    if (canManageMeet) {
        const splashSplit = splash.split || {};

        splitTimes = splashSplit.splittimes
            ? splashSplit.splittimes.split(",")
            : [];
    } else {
        splitTimes = splash.splittimes ? splash.splittimes.split(",") : [];
    }

    return splitTimes;
};

export const getTeamNameFromSplash = ({ splash }) => {
    if (!splash) return "";

    const relayName = splash.relayname ? ` (${splash.relayname})` : "";
    let teamName = splash.team
        ? splash.team.abbr || splash.team.name
        : "Unattached";

    if (splash.squad) {
        teamName = splash.squad.display_name;
    }

    if (splash.isUnattached) {
        teamName = "Unattached";
    }

    return `${teamName}${relayName}`;
};

const padTdrTime = (num, size) => {
    const paddedNumber = `0000${num}`;
    return paddedNumber.substr(paddedNumber.length - size);
};

export const formatTdrClock = (time) => {
    const minutes = Math.trunc(time / (1000 * 60));
    const seconds = Math.trunc(time / 1000) % 60;
    const paddedSeconds = padTdrTime(seconds, 2);
    const milliseconds = Math.trunc(time / 100) % 10;
    const baseTime = minutes > 0 ? `${minutes}:${paddedSeconds}` : seconds;

    return `${baseTime}.${milliseconds}`;
};
