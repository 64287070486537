import React from "react";
import PropTypes from "prop-types";

import DualScoreTable from "~/shared/components/MeetCore/Scoreboard/Scenes/TeamScores/DualScoreTable";
import TeamScoreTable from "~/shared/components/MeetCore/Scoreboard/Scenes/TeamScores//TeamScoreTable";
import styles from "~/shared/components/MeetCore/Scoreboard/Scenes/Scenes.less";

function TeamScores({
    teamScoresData = {},
    hideHeader = false,
    hideBodyScroll = false,
    viewportFit = false,
}) {
    const { isMeetDualScore, genderName, teamScores, dualScores } =
        teamScoresData || {};

    function renderHeader() {
        if (hideHeader) return null;

        return (
            <div className={styles.header}>
                <div>
                    <h1 className={`u-text-upcase ${styles.headerTitle}`}>
                        Team scores
                    </h1>
                    <ul className={`u-text-upcase ${styles.headerMeta}`}>
                        <li>
                            {genderName === "Both" ? "Combined" : genderName}
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    function renderContent() {
        if (isMeetDualScore) return <DualScoreTable dualScores={dualScores} />;

        return <TeamScoreTable teamScores={teamScores} />;
    }

    return (
        <div
            data-scoreboard-window={hideBodyScroll ? "true" : "false"}
            className={viewportFit ? styles.scoreboardFit : styles.scoreboard}
        >
            {renderHeader()}
            <div className={styles.body}>{renderContent()}</div>
        </div>
    );
}

TeamScores.propTypes = {
    teamScoresData: PropTypes.shape({}),
    hideHeader: PropTypes.bool,
    hideBodyScroll: PropTypes.bool,
    viewportFit: PropTypes.bool,
};

export default TeamScores;
