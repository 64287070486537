import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import BatchDisplay from "~/shared/components/MeetCore/Scoreboard/Scenes/BatchDisplay";
import styles from "~/shared/components/MeetCore/Scoreboard/Scenes/Scenes.less";
import { TIMECODE_MAP } from "~/shared/constants";
import { decimalToSwim } from "~/shared/utils";

const calculatePositions = (results) => {
    if (!results || !results.length) return [];

    let lastPosition = 0;
    let lastTime = null;

    return results.map((result, index) => {
        let position;

        // If the time is the same as the last result, keep the same position
        if (result.time === lastTime) {
            position = lastPosition;
        } else {
            position = index + 1;
            lastPosition = position;
            lastTime = result.time;
        }

        return { ...result, position };
    });
};

const getTimeDisplay = ({ time, timecode, isDiving }) => {
    if (timecode) return TIMECODE_MAP[timecode];
    if (isDiving) return parseFloat(time).toFixed(2);
    return decimalToSwim(time);
};

function EventResults({
    eventResults = {},
    hideTeam = false,
    hideHeader = false,
    hideBodyScroll = false,
    viewportFit = false,
}) {
    const { eventName, ageGroupName, results: _results } = eventResults || {};

    const rankedResults = calculatePositions(_results);
    const results = !ageGroupName ? rankedResults : _results;

    const hideSwimmer = results
        ? results.some((result) => result.isRelay)
        : false;

    function renderHeader() {
        if (hideHeader) return null;

        return (
            <div className={styles.header}>
                <div>
                    <h1 className={`u-text-upcase ${styles.headerTitle}`}>
                        {eventName}
                    </h1>
                    <ul className={`u-text-upcase ${styles.headerMeta}`}>
                        <li>Results</li>
                        {ageGroupName && <li>{ageGroupName}</li>}
                    </ul>
                </div>
            </div>
        );
    }

    const renderResultRow = (result) => {
        const {
            id,
            position,
            place: _place,
            swimmerName,
            teamName,
            time,
            timecode,
            isDiving,
        } = result || {};

        const place = !ageGroupName ? position : _place;
        const placeDisplay = place || "-";

        return (
            <tr key={id}>
                <td
                    className={`${styles.cellPlace} ${styles.cellPlaceFinished}`}
                >
                    {timecode ? "-" : placeDisplay}
                </td>

                {viewportFit ? (
                    <>
                        {!hideSwimmer && (
                            <td className={styles.cellPrimary}>
                                {swimmerName}
                            </td>
                        )}
                        {!hideTeam && (
                            <td
                                className={classNames({
                                    [styles.cellTeam]: !hideSwimmer,
                                    [styles.cellPrimary]: hideSwimmer,
                                })}
                            >
                                {teamName}
                            </td>
                        )}
                    </>
                ) : (
                    <td className={styles.cellPrimary}>
                        <div
                            className={classNames({
                                "u-text-truncate": true,
                                "u-is-hidden": hideSwimmer,
                            })}
                        >
                            {swimmerName || (
                                <div className="u-is-invisible">No swimmer</div>
                            )}
                        </div>
                        {!hideTeam && (
                            <div
                                className={classNames({
                                    "u-text-truncate": true,
                                    [styles.cellTeam]: !hideSwimmer,
                                })}
                            >
                                {teamName || (
                                    <div className="u-is-invisible">
                                        No team
                                    </div>
                                )}
                            </div>
                        )}
                    </td>
                )}
                <td className={styles.cellResult}>
                    {getTimeDisplay({ time, timecode, isDiving })}
                </td>
            </tr>
        );
    };

    return (
        <div
            data-scoreboard-window={hideBodyScroll ? "true" : "false"}
            className={viewportFit ? styles.scoreboardFit : styles.scoreboard}
        >
            {renderHeader()}
            <div className={styles.body}>
                {results ? (
                    <BatchDisplay
                        items={results}
                        renderItem={renderResultRow}
                    />
                ) : null}
            </div>
        </div>
    );
}

EventResults.propTypes = {
    eventResults: PropTypes.shape({}),
    hideTeam: PropTypes.bool,
    hideHeader: PropTypes.bool,
    hideBodyScroll: PropTypes.bool,
    viewportFit: PropTypes.bool,
};

export default EventResults;
