import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import objectHash from "object-hash";
import { AnimatePresence, motion } from "motion/react";

import styles from "~/shared/components/MeetCore/Scoreboard/Scenes/Scenes.less";

const ITEMS_PER_BATCH = 10;
const FADE_VARIANTS = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
};

function BatchDisplay({ items, renderItem }) {
    const [currentBatchIndex, setCurrentBatchIndex] = useState(0);

    const totalBatches = Math.max(1, Math.ceil(items.length / ITEMS_PER_BATCH));
    const itemsHash = objectHash(items);

    useEffect(() => {
        setCurrentBatchIndex(0);
    }, [itemsHash]);

    // Interval for cycling through batches
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentBatchIndex((prevIndex) => (prevIndex + 1) % totalBatches);
        }, 9000);

        return () => clearInterval(interval);
    }, [totalBatches]);

    // Generate the current batch of items
    const currentBatch =
        items.length > 0
            ? items.slice(
                  currentBatchIndex * ITEMS_PER_BATCH,
                  (currentBatchIndex + 1) * ITEMS_PER_BATCH,
              )
            : [];

    return (
        <AnimatePresence initial={false} mode="wait">
            <motion.div
                key={currentBatchIndex}
                variants={FADE_VARIANTS}
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 0.3 }}
            >
                <table className={`table ${styles.table}`}>
                    <tbody>
                        {currentBatch.map((item) => renderItem(item))}
                    </tbody>
                </table>
            </motion.div>
        </AnimatePresence>
    );
}

BatchDisplay.propTypes = {
    items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    renderItem: PropTypes.func.isRequired,
};

export default BatchDisplay;
