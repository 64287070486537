import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "~/shared/components/MeetCore/Scoreboard/Scenes/Scenes.less";

function EventHeader({
    eventName = "",
    eventNumber = null,
    heatNumber = null,
    eventHeatsCount = null,
}) {
    const showNumberOfHeats = heatNumber <= eventHeatsCount;

    return (
        <div>
            <h1
                className={classNames({
                    "u-text-upcase": true,
                    [styles.headerTitle]: true,
                })}
            >
                {eventName}
            </h1>
            <ul
                className={classNames({
                    "u-text-upcase": true,
                    [styles.headerMeta]: true,
                })}
            >
                <li>Event {eventNumber}</li>
                <li>
                    Heat {heatNumber}
                    {showNumberOfHeats ? ` of ${eventHeatsCount}` : null}
                </li>
            </ul>
        </div>
    );
}

EventHeader.propTypes = {
    eventName: PropTypes.string,
    eventNumber: PropTypes.number,
    heatNumber: PropTypes.number,
    eventHeatsCount: PropTypes.number,
};

export default EventHeader;
