import React from "react";
import { useSelector } from "react-redux";

function TopSwimmers() {
    const meetId = useSelector((state) => state.meetId);
    const isUsaPrep = useSelector((state) => state.isUsaPrep);
    const pointsystemTitle = useSelector((state) => state.pointsystemTitle);
    const pointsystemDisplayName = useSelector(
        (state) => state.pointsystemDisplayName,
    );
    const hasScore = useSelector((state) => state.hasScore);
    const topSwimmers = useSelector((state) => state.topSwimmers);
    const showMoreSwimmers = useSelector((state) => state.showMoreSwimmers);

    function renderMore() {
        if (!showMoreSwimmers) return null;

        return (
            <caption className="c-table-clean__caption c-table-clean__caption--bottom">
                <a
                    className="c-more c-more--small"
                    href={`/results/${meetId}/topswimmers/${window.location.search}`}
                >
                    More
                </a>
            </caption>
        );
    }

    function renderHead() {
        return (
            <thead>
                <tr>
                    <th colSpan="2">Name</th>
                    <th>Team</th>
                    {isUsaPrep && <th>Class</th>}
                    {hasScore && (
                        <>
                            <th className="c-table-clean__col-fit u-text-end">
                                Gold
                            </th>
                            <th className="c-table-clean__col-fit u-text-end">
                                Silver
                            </th>
                            <th className="c-table-clean__col-fit u-text-end">
                                Bronze
                            </th>
                            <th className="c-table-clean__col-fit u-text-end">
                                Score
                            </th>
                        </>
                    )}
                    <th
                        className="c-table-clean__col-fit u-text-end"
                        title={pointsystemTitle}
                    >
                        {pointsystemDisplayName}
                    </th>
                </tr>
            </thead>
        );
    }

    function renderBody() {
        return (
            <tbody>
                {topSwimmers.map((swimmer, index) => (
                    <tr key={swimmer.id}>
                        <td className="c-table-clean__col-fit u-pr0 u-text-center">
                            {index + 1}
                        </td>
                        <td>
                            <a
                                className="u-text-semi"
                                href={`/results/${meetId}/swimmer/${swimmer.id}/${window.location.search}`}
                            >
                                {swimmer.short_name}
                            </a>
                        </td>
                        <td className="u-text-truncate">
                            {swimmer.meet_team ? (
                                <a
                                    href={`/results/${meetId}/team/${swimmer.meet_team.id}/${window.location.search}`}
                                    title={swimmer.meet_team.display_name}
                                >
                                    <img
                                        className="c-table-clean__img u-mr--"
                                        src={swimmer.meet_team.small_logo_url}
                                        alt={`${swimmer.meet_team.short_name} logo`}
                                    />{" "}
                                    <span className="hidden-xs">
                                        {swimmer.meet_team.short_name}
                                    </span>
                                </a>
                            ) : (
                                <span title="Unattached">UN</span>
                            )}
                        </td>
                        {isUsaPrep && (
                            <td>{swimmer.school_class || "\u2013"}</td>
                        )}
                        {hasScore && (
                            <>
                                <td className="u-text-end">
                                    {swimmer.meet_gold
                                        ? Number(swimmer.meet_gold).toFixed()
                                        : "\u2013"}
                                </td>
                                <td className="u-text-end">
                                    {swimmer.meet_silver
                                        ? Number(swimmer.meet_silver).toFixed()
                                        : "\u2013"}
                                </td>
                                <td className="u-text-end">
                                    {swimmer.meet_bronze
                                        ? Number(swimmer.meet_bronze).toFixed()
                                        : "\u2013"}
                                </td>
                                <td className="u-text-end">
                                    {swimmer.meet_score
                                        ? Number(swimmer.meet_score).toFixed()
                                        : "\u2013"}
                                </td>
                            </>
                        )}
                        <td className="u-text-end">
                            {swimmer.meet_perf
                                ? Number(swimmer.meet_perf).toFixed(1)
                                : "\u2013"}
                        </td>
                    </tr>
                ))}
            </tbody>
        );
    }

    if (!topSwimmers || !topSwimmers.length) return null;

    return (
        <div className="c-card c-card--large u-overflow-hidden">
            <div className="c-card__item">
                <h2 className="c-title c-title--small">High point</h2>
            </div>
            <div className="c-table-clean--responsive">
                <table className="c-table-clean c-table-clean--middle table table-hover">
                    {renderMore()}
                    {renderHead()}
                    {renderBody()}
                </table>
            </div>
        </div>
    );
}

export default TopSwimmers;
