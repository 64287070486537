import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function HeaderExpandButton({ isExpanded }) {
    return (
        <button type="button" className="btn c-scoreboard-sheet__btn">
            <i
                className={classNames({
                    "fa-regular fa-chevron-up": true,
                    "fa-rotate-180": isExpanded,
                })}
            />
        </button>
    );
}

HeaderExpandButton.propTypes = {
    isExpanded: PropTypes.bool.isRequired,
};

export default HeaderExpandButton;
