import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "~/shared/components/MeetCore/Scoreboard/Scenes/Scenes.less";

function TableRow({
    lane = "",
    swimmerName = "",
    teamName = "",
    place = null,
    time = "",
    hasFinished = false,
    showLaneTimes = true,
    hideSwimmer = false,
    hideTeam = false,
    viewportFit = false,
}) {
    return (
        <tr>
            <td className={styles.cellLane}>{lane}</td>

            {viewportFit ? (
                <>
                    {!hideSwimmer && (
                        <td className={styles.cellPrimary}>{swimmerName}</td>
                    )}
                    {!hideTeam && (
                        <td
                            className={classNames({
                                [styles.cellTeam]: !hideSwimmer,
                                [styles.cellPrimary]: hideSwimmer,
                            })}
                        >
                            {teamName}
                        </td>
                    )}
                </>
            ) : (
                <td className={styles.cellPrimary}>
                    <div
                        className={classNames({
                            "u-text-truncate": true,
                            "u-is-hidden": hideSwimmer,
                        })}
                    >
                        {swimmerName || (
                            <div className="u-is-invisible">No swimmer</div>
                        )}
                    </div>
                    {!hideTeam && (
                        <div
                            className={classNames({
                                "u-text-truncate": true,
                                [styles.cellTeam]: !hideSwimmer,
                            })}
                        >
                            {teamName || (
                                <div className="u-is-invisible">No team</div>
                            )}
                        </div>
                    )}
                </td>
            )}

            <td
                className={classNames({
                    [styles.cellPlace]: true,
                    [styles.cellPlaceFinished]: hasFinished,
                    [styles.cellInvisible]: !showLaneTimes,
                })}
            >
                {place}
            </td>

            <td
                className={classNames({
                    [styles.cellResult]: true,
                    [styles.cellInvisible]: !showLaneTimes,
                })}
            >
                {time}
            </td>
        </tr>
    );
}

TableRow.propTypes = {
    lane: PropTypes.string,
    swimmerName: PropTypes.string,
    teamName: PropTypes.string,
    place: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.bool,
    ]),
    time: PropTypes.string,
    hasFinished: PropTypes.bool,
    showLaneTimes: PropTypes.bool,
    hideSwimmer: PropTypes.bool,
    hideTeam: PropTypes.bool,
    viewportFit: PropTypes.bool.isRequired,
};

export default TableRow;
