import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { PanelGroup, Panel } from "react-bootstrap";

import {
    fetchLiveMeets,
    changeMeet,
} from "~/meetScoreboard/actions/controller";

import Scoreboard from "~/meetScoreboard/components/Scoreboard";
import ScoreboardListSkeleton from "~/meetScoreboard/components/ScoreboardListSkeleton";

function ScoreboardList() {
    const liveMeets = useSelector((state) => state.liveMeets);
    const meetId = useSelector((state) => state.meetId);

    const [activeKey, setActiveKey] = useState(meetId);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const load = async () => {
            await fetchLiveMeets();
            setIsLoading(false);
        };

        load();
    }, []);

    const onSelectMeet = (nextMeetId) => {
        const nextMeet = liveMeets.find((meet) => meet.id === nextMeetId);

        if (!nextMeet) return;

        setActiveKey(nextMeetId);
        changeMeet({ meet: nextMeet });
    };

    function renderScoreboard({ meet }) {
        if (!meet || meet.id !== meetId) return null;

        return (
            <Scoreboard
                meetLink={meet.absolute_url}
                meetIsHidden={meet.is_hidden}
                showEmptyMessage
            />
        );
    }

    function renderEmptyState() {
        return (
            <div className="c-blankslate c-blankslate--small c-blankslate--clean u-background-transparent">
                <h3 className="c-blankslate__title u-color-mute-invert">
                    There are no live meets available at this moment. Please try
                    again later
                </h3>
            </div>
        );
    }

    if (isLoading) return <ScoreboardListSkeleton />;

    if (!liveMeets || !liveMeets.length) return renderEmptyState();

    return (
        <PanelGroup
            accordion
            id="live-meets-accordion"
            activeKey={activeKey}
            onSelect={onSelectMeet}
            className="u-mb0"
        >
            {liveMeets.map((meet) => (
                <Panel key={meet.id} eventKey={meet.id} bsStyle="dark">
                    <Panel.Heading>
                        <Panel.Title toggle>
                            <div className="o-flag">
                                <div className="o-flag__img">
                                    <img
                                        className="c-avatar c-avatar--micro u-object-contain"
                                        src={meet.logo}
                                        alt={meet.display_name}
                                        width="38"
                                        height="38"
                                        loading="lazy"
                                    />
                                </div>
                                <div className="o-flag__body">
                                    <h3 className="c-title c-title--tiny u-mb--">
                                        {meet.display_name}
                                    </h3>
                                    <ul className="o-list-inline o-list-inline--dotted u-color u-color-mute-invert u-text-small">
                                        <li>
                                            <span className="c-label c-label--live">
                                                LIVE
                                            </span>
                                        </li>
                                        {meet.is_hidden ? (
                                            <li>
                                                <div className="c-chip c-chip--danger c-chip--outline c-chip--tiny u-m0">
                                                    <span className="c-chip__text">
                                                        Hidden
                                                    </span>
                                                </div>
                                            </li>
                                        ) : null}
                                        <li>{meet.location}</li>
                                    </ul>
                                </div>
                            </div>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        {renderScoreboard({ meet })}
                    </Panel.Body>
                </Panel>
            ))}
        </PanelGroup>
    );
}

export default ScoreboardList;
