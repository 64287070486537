import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import Scoreboard from "~/meetScoreboard/components/Scoreboard";
import TopTeams from "~/meetScoreboard/components/TopTeams";
import DualScores from "~/meetScoreboard/components/DualScores";
import TopSwimmers from "~/meetScoreboard/components/TopSwimmers";
import TopSwims from "~/meetScoreboard/components/TopSwims";
import EventList from "~/meetScoreboard/components/EventList";
import ScoreboardList from "~/meetScoreboard/components/ScoreboardList";

function App() {
    const meetId = useSelector((state) => state.meetId);
    const topTeams = useSelector((state) => state.topTeams);
    const topSquads = useSelector((state) => state.topSquads);
    const dualScores = useSelector((state) => state.dualScores);
    const topSwimmers = useSelector((state) => state.topSwimmers);
    const topSwims = useSelector((state) => state.topSwims);
    const isDoubleDual = useSelector((state) => state.isDoubleDual);
    const isIntrasquad = useSelector((state) => state.isIntrasquad);
    const onlyRenderScoreboard = useSelector(
        (state) => state.onlyRenderScoreboard,
    );
    const showLiveMeets = useSelector((state) => state.showLiveMeets);

    const shouldInjectTables = useMemo(() => {
        return window.location.pathname === `/results/${meetId}/`;
    }, [meetId]);

    const hideMeetDashboardPlaceholders = useMemo(() => {
        return Boolean(
            topTeams || topSquads || dualScores || topSwimmers || topSwims,
        );
    }, [topTeams, topSquads, dualScores, topSwimmers, topSwims]);

    useEffect(() => {
        const meetDashboardPlaceholders = document.getElementById(
            "meet-dashboard-placeholders",
        );

        if (meetDashboardPlaceholders && hideMeetDashboardPlaceholders) {
            meetDashboardPlaceholders.classList.add("u-is-hidden");
        }
    }, [hideMeetDashboardPlaceholders]);

    function renderScoreboardContent() {
        if (showLiveMeets) return <ScoreboardList />;

        return <Scoreboard />;
    }

    function renderTeamScores() {
        if (topTeams && topTeams.length === 2) {
            return <DualScores />;
        }

        if (dualScores && dualScores.length && isDoubleDual) {
            return <DualScores doubleDual />;
        }

        if (isIntrasquad) {
            return <TopTeams intraSquads />;
        }

        return <TopTeams />;
    }

    function renderInjectibleContent() {
        if (onlyRenderScoreboard) return null;

        if (shouldInjectTables) {
            return (
                <>
                    {renderTeamScores()}
                    <TopSwimmers />
                    <TopSwims />
                    <EventList />
                </>
            );
        }

        return <EventList />;
    }

    return (
        <>
            {renderScoreboardContent()}
            {renderInjectibleContent()}
        </>
    );
}

export default App;
