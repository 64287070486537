import { DEFAULT_SCOREBOARD_CONFIG } from "~/shared/constants";

const getInitialState = () => {
    const { dataset } = document.getElementById("meet-scoreboard") || {};
    const {
        wsProtocol,
        meetId,
        numLanes,
        fromPage,
        selectedGender,
        selectedAgegroup,
        selectedVarsity,
        selectedSort,
        isUsaPrep,
        isDoubleDual,
        isIntrasquad,
        pointsystem,
        pointsystemTitle,
        pointsystemDisplayName,
        onlyRenderScoreboard,
        isScoreboardSheet,
        hideBodyScroll,
        viewportFit,
        showVisibilityButton,
        showVisitors,
        showLiveMeets,
    } = dataset || {};

    return {
        wsProtocol,
        meetId: meetId ? Number(meetId) : null,
        numLanes: numLanes ? Number(numLanes) : null,
        fromPage,
        selectedGender,
        selectedAgegroup,
        selectedVarsity,
        selectedSort,
        isUsaPrep: Boolean(isUsaPrep),
        isDoubleDual: Boolean(isDoubleDual),
        isIntrasquad: Boolean(isIntrasquad),
        pointsystem,
        pointsystemTitle,
        pointsystemDisplayName,
        scoreboardConfig: DEFAULT_SCOREBOARD_CONFIG,
        ctsScoreboardData: null,
        tdrScoreboardData: null,
        scoreboardVisitorsCount: 1,
        scoreboardTeamScoresData: null,
        scoreboardEventResultsData: null,
        hasScore: false,
        topTeams: null,
        showMoreTeams: false,
        topSquads: null,
        dualScores: null,
        topSwimmers: null,
        showMoreSwimmers: false,
        topSwims: null,
        showMoreSwims: false,
        eventList: null,
        showRoundStatus: false,
        numberWidth: null,
        onlyRenderScoreboard: onlyRenderScoreboard === "true",
        isScoreboardSheet: isScoreboardSheet === "true",
        hideBodyScroll: hideBodyScroll === "true",
        viewportFit: viewportFit === "true",
        showVisibilityButton: showVisibilityButton === "true",
        showVisitors: showVisitors === "true",
        showLiveMeets: showLiveMeets === "true",
        liveMeets: [],
    };
};

export default getInitialState;
