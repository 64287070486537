import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

function TopTeams({ intraSquads = false }) {
    const meetId = useSelector((state) => state.meetId);
    const pointsystemTitle = useSelector((state) => state.pointsystemTitle);
    const pointsystemDisplayName = useSelector(
        (state) => state.pointsystemDisplayName,
    );
    const hasScore = useSelector((state) => state.hasScore);
    const topTeams = useSelector((state) => state.topTeams);
    const topSquads = useSelector((state) => state.topSquads);
    const showMoreTeams = useSelector((state) => state.showMoreTeams);

    const teams = useMemo(() => {
        if (intraSquads) return topSquads;

        return topTeams;
    }, [intraSquads, topSquads, topTeams]);

    function renderMore() {
        if (intraSquads || !showMoreTeams) return null;

        return (
            <caption className="c-table-clean__caption c-table-clean__caption--bottom">
                <a
                    className="c-more c-more--small"
                    href={`/results/${meetId}/topteams/${window.location.search}`}
                >
                    More
                </a>
            </caption>
        );
    }

    function renderHead() {
        return (
            <thead>
                <tr>
                    <th colSpan="2">Team</th>
                    {hasScore && (
                        <>
                            <th className="c-table-clean__col-fit u-text-end">
                                Gold
                            </th>
                            <th className="c-table-clean__col-fit u-text-end">
                                Silver
                            </th>
                            <th className="c-table-clean__col-fit u-text-end">
                                Bronze
                            </th>
                            <th className="c-table-clean__col-fit u-text-end">
                                Score
                            </th>
                        </>
                    )}
                    <th
                        className="c-table-clean__col-fit u-text-end"
                        title={pointsystemTitle}
                    >
                        {pointsystemDisplayName}
                    </th>
                </tr>
            </thead>
        );
    }

    function renderBody() {
        return (
            <tbody>
                {teams.map((team, index) => (
                    <tr key={team.id}>
                        <td className="c-table-clean__col-fit u-pr0 u-text-center">
                            {index + 1}
                        </td>
                        <td className="u-pr+++">
                            <a
                                className="u-text-semi u-nowrap"
                                href={`/results/${meetId}/team/${team.id}/${window.location.search}`}
                            >
                                <img
                                    className="c-table-clean__img u-mr-- u-nowrap"
                                    src={team.small_logo_url}
                                    alt={`${team.short_name} logo`}
                                />{" "}
                                {team.short_name}
                            </a>
                        </td>
                        {hasScore && (
                            <>
                                <td className="u-text-end">
                                    {team.meet_gold
                                        ? Number(team.meet_gold).toFixed()
                                        : "\u2013"}
                                </td>
                                <td className="u-text-end">
                                    {team.meet_silver
                                        ? Number(team.meet_silver).toFixed()
                                        : "\u2013"}
                                </td>
                                <td className="u-text-end">
                                    {team.meet_bronze
                                        ? Number(team.meet_bronze).toFixed()
                                        : "\u2013"}
                                </td>
                                <td className="u-text-end">
                                    {team.meet_score
                                        ? Number(team.meet_score).toFixed()
                                        : "\u2013"}
                                </td>
                            </>
                        )}
                        <td className="u-text-end">
                            {team.meet_perf
                                ? Number(team.meet_perf).toFixed(1)
                                : "\u2013"}
                        </td>
                    </tr>
                ))}
            </tbody>
        );
    }

    if (!teams || !teams.length) return null;

    return (
        <div className="c-card c-card--large u-overflow-hidden">
            <div className="c-card__item">
                <h2 className="c-title c-title--small">Teams</h2>
            </div>
            <div className="c-table-clean--responsive">
                <table className="c-table-clean c-table-clean--middle table table-hover">
                    {renderMore()}
                    {renderHead()}
                    {renderBody()}
                </table>
            </div>
        </div>
    );
}

TopTeams.propTypes = {
    intraSquads: PropTypes.bool,
};

export default TopTeams;
