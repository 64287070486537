/* eslint-disable react/no-array-index-key */

import React, { useEffect, useMemo } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import _ from "lodash";

function EventList() {
    const eventList = useSelector((state) => state.eventList);
    const showRoundStatus = useSelector((state) => state.showRoundStatus);
    const numberWidth = useSelector((state) => state.numberWidth);

    const hideMeetEventsPlaceholder = useMemo(() => {
        return Boolean(eventList);
    }, [eventList]);

    const sessions = useMemo(() => {
        if (!eventList || !eventList.length) return [];

        return _.groupBy(
            _.sortBy(eventList, [
                (event) => event.numeric_number,
                (event) => event.session,
                (event) => event.session_number,
            ]),
            (event) => event.session,
        );
    }, [eventList]);

    useEffect(() => {
        const meetEventsPlaceholder = document.getElementById(
            "meet-events-placeholder",
        );

        if (meetEventsPlaceholder && hideMeetEventsPlaceholder) {
            meetEventsPlaceholder.classList.add("u-is-hidden");
        }
    }, [hideMeetEventsPlaceholder]);

    function renderContent() {
        if (!sessions) return null;

        return _.entries(sessions).map(([sessionName, sessionEvents]) => {
            if (sessionName) {
                return (
                    <li
                        key={sessionName}
                        className="o-list-block__item js-event-item"
                    >
                        <h4 className="c-subheader c-subheader--mute u-ph u-mb- u-text-truncate">
                            {sessionName}
                        </h4>
                        {sessionEvents.map((event, index) => (
                            <a
                                key={index}
                                className="c-nav__item"
                                href={event.href}
                            >
                                <div className="o-media o-media--flush">
                                    {event.number && (
                                        <div
                                            className="o-media__img"
                                            style={{
                                                width: `${numberWidth}px`,
                                            }}
                                            title={
                                                showRoundStatus
                                                    ? event.status_title
                                                    : ""
                                            }
                                        >
                                            <span
                                                className={
                                                    showRoundStatus
                                                        ? event.status_color
                                                        : ""
                                                }
                                            >
                                                {event.number}
                                            </span>
                                        </div>
                                    )}
                                    <div
                                        className="o-media__body u-text-truncate"
                                        title={event.name}
                                    >
                                        {event.name}
                                    </div>
                                </div>
                            </a>
                        ))}
                    </li>
                );
            }

            return sessionEvents.map((event, index) => (
                <li key={index} className="js-event-item">
                    <a className="c-nav__item" href={event.href}>
                        <div className="o-media o-media--flush">
                            {event.number && (
                                <div
                                    className="o-media__img"
                                    style={{
                                        width: `${numberWidth}px`,
                                    }}
                                    title={
                                        showRoundStatus
                                            ? event.status_title
                                            : ""
                                    }
                                >
                                    <span
                                        className={
                                            showRoundStatus
                                                ? event.status_color
                                                : ""
                                        }
                                    >
                                        {event.number}
                                    </span>
                                </div>
                            )}
                            <div
                                className="o-media__body u-text-truncate"
                                title={event.name}
                            >
                                {event.name}
                            </div>
                        </div>
                    </a>
                </li>
            ));
        });
    }

    const portal = document.getElementById("meet-events-portal");

    return portal && createPortal(renderContent(), portal);
}

export default EventList;
