import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "~/shared/components/MeetCore/Scoreboard/Scenes/Scenes.less";

function Timer({ children }) {
    return (
        <h1
            className={classNames({
                "c-title": true,
                [styles.headerTime]: true,
            })}
        >
            {children}
        </h1>
    );
}

Timer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Timer;
