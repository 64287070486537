import React from "react";

function HeaderLiveLabel() {
    return (
        <li className="c-scoreboard-sheet__live-color">
            <i className="fa-solid fa-circle-small fa-fade u-mr-" />
            Live
        </li>
    );
}

export default HeaderLiveLabel;
