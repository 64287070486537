import React from "react";
import { PanelGroup, Panel } from "react-bootstrap";

import ScoreboardSkeleton from "~/meetScoreboard/components/ScoreboadSkeleton";

function ScoreboardListSkeleton() {
    return (
        <PanelGroup accordion id="scoreboard-list-skeleton" className="u-mb0">
            <Panel bsStyle="dark">
                <Panel.Heading>
                    <Panel.Title toggle>
                        <div className="o-flag">
                            <div className="o-flag__img">
                                <div className="c-skeleton is-avatar" />
                            </div>
                            <div className="o-flag__body">
                                <div
                                    className="c-skeleton is-title u-mb--"
                                    style={{ width: "225px" }}
                                />
                                <div className="o-spacer">
                                    <span
                                        className="c-skeleton is-meta"
                                        style={{ width: "33px" }}
                                    />
                                    <span
                                        className="c-skeleton is-meta"
                                        style={{ width: "99px" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                    <ScoreboardSkeleton />
                </Panel.Body>
            </Panel>
        </PanelGroup>
    );
}

export default ScoreboardListSkeleton;
