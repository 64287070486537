import React from "react";

function DivingEventMessage() {
    return (
        <div className="c-blankslate c-blankslate--large c-blankslate--clean u-background-transparent">
            <h4 className="c-blankslate__title u-color-white">
                Diving in progress. Stay tuned.
            </h4>
        </div>
    );
}

export default DivingEventMessage;
